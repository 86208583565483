import Vue from 'vue'
import VueRouter from 'vue-router'
// import Home from '../views/home/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    // component: Home
    component: () => import('../views/home/index.vue')
    
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/search/index.vue')
  },
  {
    path: '/search/:series',
    name: 'SearchSeries',
    component: () => import('../views/search/index.vue')
  },
  {
    path: '/sale',
    name: 'Sale',
    component: () => import('../views/sale/index.vue')
  },
  {
    path: '/benefits',
    name: 'Benefits',
    component: () => import('../views/benefits/index.vue')
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import('../views/collection/index.vue')
  },
  {
    path: '/comparison',
    name: 'Comparison',
    component: () => import('../views/comparison/index.vue')
  },
  {
    path: '/info/:id',
    name: 'Info',
    component: () => import('../views/info/index.vue')
  },
  {
    path: "/info",
    redirect: "/",
  },
  {
    path: '/assistant',
    name: 'Assistant',
    component: () => import('../views/assistant/index.vue')
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/",
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior: function (to) {
    if (to.hash) {
      return {selector: to.hash}
    } else {
      return { x: 0, y: 0 }
    }
  },
})

export default router
