import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
    strict: true,
    state: {
        allAge: [],
        allBrand: [],
        allDealer: [],
        allInteriorColor: ['黑','米','咖啡','橘','紅','藍','其他'],
        allSeries:[],
        allColorCategory:[],
        hostUrl: process.env.VUE_APP_hostUrl,
        collectionIdArr:[],
        collectionInfoArr:[],
        comparisonIdArr:[],
        comparisonInfoArr:[],
    },
    mutations: {
        SETALLAGE(state,allAge){
            state.allAge=allAge
        },
        SETALLBRAND(state,allBrand){
            state.allBrand=allBrand
        },
        SETALLDEALER(state,allDealer){
            state.allDealer=allDealer
        },
        SETALLSERIES(state,allSeries){
            state.allSeries=allSeries
        },
        SETALLCOLORCATEGORY(state,allColorCategory){
            state.allColorCategory=allColorCategory
        },
        SETCOLLECTION(state,{id,element}){
            if(state.collectionIdArr.indexOf(id)==-1){
                state.collectionIdArr.push(id)
                state.collectionInfoArr.push(element)
            }else{
                state.collectionIdArr.forEach((element,index) => {
                    if(element===id){
                        state.collectionIdArr.splice(index,1)
                        state.collectionInfoArr.splice(index,1)
                    }
                });
            }
            localStorage.setItem("collectionIdArr", state.collectionIdArr)
        },
        SETCOMPARISON(state,{id,element}){
            if(state.comparisonIdArr.indexOf(id)==-1){
                state.comparisonIdArr.push(id)
                state.comparisonInfoArr.push(element)
            }else{
                state.comparisonIdArr.forEach((element,index) => {
                    if(element===id){
                        state.comparisonIdArr.splice(index,1)
                        state.comparisonInfoArr.splice(index,1)
                    }
                });
            }
            localStorage.setItem("comparisonIdArr", state.comparisonIdArr)
        }
    },
    actions: {
        GETALLAGE(context){
            return axios
                .get("https://contact.bmw.com.tw/api/testdrive/getallage")
                .then(function (response) {
                    context.commit("SETALLAGE",response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        GETALLBRAND(context){
            return axios
                .get("https://contact.bmw.com.tw/api/testdrive/getallbrand")
                .then(function (response) {
                    context.commit("SETALLBRAND",response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        GETALLDEALER(context){
            return axios
                .post(context.state.hostUrl+"/api/Allshow")
                .then(function (response) {
                    context.commit("SETALLDEALER",response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        GETALLSERIES(context){
            return axios
                .post(context.state.hostUrl+"/api/AllSeries")
                .then(function (response) {
                    context.commit("SETALLSERIES",response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        GETALLCOLORCATEGORY(context){
            return axios
                .post(context.state.hostUrl+"/api/AllColorCategory")
                .then(function (response) {
                    context.commit("SETALLCOLORCATEGORY",response.data)
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        GETCOLLECTION(context,{id,element}){
            context.commit("SETCOLLECTION",{id,element})
        },
        GETCOMPARISON(context,{id,element}){
            context.commit("SETCOMPARISON",{id,element})
        }
    },
    modules: {
    }
})
