<script>
export default {
    name: "loading",
    props:{
        isActiveLoading:{
            type: Boolean,
            required: true
        }
    },
    methods: {
        lightboxToggle(){
            // console.log("lightboxToggle")
            let vm=this
            vm.$emit("update:isActiveLoading", false);
        }
    },
}
</script>

<template src="./template.pug" lang="pug"></template>
<style src="./style.sass" lang="sass" scoped>
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
</style>