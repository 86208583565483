<template lang="pug">
  #app
    transition(name="fadeLoading")
        loading(v-show="isActiveLoading" :isActiveLoading.sync="isActiveLoading")
    nav_header
    keep-alive(include="search")
        router-view( :isActiveLoading.sync="isActiveLoading" :isActiveList.sync="isActiveList" :isActiveRule.sync="isActiveRule" :prePage="prePage")
    faq(:isActiveFaq.sync="isActiveFaq")
    rule(:isActiveRule.sync="isActiveRule")
    list(:isActiveList.sync="isActiveList")
    compare_fixed(:nowPage="nowPage")
    nav_footer(:isActiveFaq.sync="isActiveFaq")
</template>


<script>
// @ is an alias to /src
import loading from '@/components/loading/index.vue'
import nav_header from '@/components/nav_header/index.vue'
import nav_footer from '@/components/nav_footer/index.vue'
import faq from '@/components/faq/index.vue'
import rule from '@/components/rule/index.vue'
import list from '@/components/list/index.vue'
import compare_fixed from '@/components/compare_fixed/index.vue'
import { nextTick } from 'vue';
import axios from 'axios'
export default {
    name: "App",
    data(){
        return{
            nowPage:'',
            prePage:'',
            isActiveFaq:false,
            isActiveRule:false,
            isActiveList:false,
            isActiveLoading:true,
        }
    },
    components: {
        nextTick,
        loading,
        nav_header,
        nav_footer,
        faq,
        rule,
        list,
        compare_fixed
    },
    computed: {
        hostUrl(){
            return this.$store.state.hostUrl
        },
        collectionIdArr(){
            return this.$store.state.collectionIdArr
        },
        collectionInfoArr(){
            return this.$store.state.collectionInfoArr
        },
        comparisonIdArr(){
            return this.$store.state.comparisonIdArr
        },
        comparisonInfoArr(){
            return this.$store.state.comparisonInfoArr
        },
    },
    watch: {
        $route() {
            let vm = this;
            if(vm.nowPage!==vm.$route.name){
                vm.prePage = vm.nowPage
                if(vm.$route.name==='SearchSeries'){
                    vm.nowPage='Search'
                }else{
                    vm.nowPage = vm.$route.name;
                }
            }
        },
    },
    mounted() {
        let vm = this;

        vm.$nextTick(function () {
            let vh = window.innerHeight * 0.01;
            document.documentElement.style.setProperty("--vh", `${vh}px`);
            vm.checkUrl()
            vm.getLocalStorage()
            vm.nowPage = vm.$route.name;
        }); 
    },
    methods: {
        getCollectionData(id){
            // console.log("getCollectionData",id)
            let vm=this
            axios
            .post(vm.hostUrl+"/api/car/"+id)
            .then(function (response) {
                if(response.data.car!==null){
                    vm.updateCollection(id,response.data.car)
                }
                
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getComparisonData(id){
            // console.log("getComparisonData",id)
            let vm=this
            axios
            .post(vm.hostUrl+"/api/car/"+id)
            .then(function (response) {
                if(response.data.car!==null){
                    vm.updateComparison(id,response.data.car)
                }
                
            })
            .catch(function (error) {
                console.log(error);
            });
        },
        getLocalStorage(){
            // console.log("getLocalStorage")
            let vm=this
            if(localStorage.getItem("collectionIdArr")!==null){
                for(let i=0;i<localStorage.getItem("collectionIdArr").split(",").length;i++){
                    vm.getCollectionData(Number(localStorage.getItem("collectionIdArr").split(",")[i]))
                }
            }
            if(localStorage.getItem("comparisonIdArr")!==null){
                for(let i=0;i<localStorage.getItem("comparisonIdArr").split(",").length;i++){
                    vm.getComparisonData(Number(localStorage.getItem("comparisonIdArr").split(",")[i]))
                }
            }
        },
        updateCollection(id,element){
            // console.log("updateCollection")
            let vm=this
            vm.$store.dispatch("GETCOLLECTION",{id,element})
        },
        updateComparison(id,element){
            // console.log("updateComparison")
            let vm=this
            vm.$store.dispatch("GETCOMPARISON",{id,element})
        },
        checkUrl(){
            let vm = this;
            // console.log("checkUrl")
            if(window.location.href.indexOf("FAQ")!==-1){
                vm.isActiveFaq=true
            }
        }
    },
};
</script>


<style lang="sass">
@import "@/assets/sass/_reset.sass"
@import "@/assets/sass/_variable.sass"
@import "@/assets/sass/_mixin.sass"
@import "@/assets/sass/_layout.sass"
</style>